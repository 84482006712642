import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map} from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ENDPOINTS, VEHICLE_BASE_URL_ENDPOINT } from 'src/app/app.constants';

@Injectable({
  providedIn: 'root'
})

export class FilenameToBase64 {

  constructor(private httpClient: HttpClient) { }

  getFileBase64Data(payload): Observable<any> {
   return this.httpClient.post(`${VEHICLE_BASE_URL_ENDPOINT}`+`${ENDPOINTS.FILENAMETOBASE64}`, payload).pipe(map((data: any) => {
      return data;
    }));
  }
};