export interface CustomerComplaintsInterface {
    complaintno: string;
    customerid: string;
    customername: string;
    complainttype: string;
}

export interface DriverComplaintsInterface {
    complaintno: string;
    driverid: string;
    drivername: string;
    complainttype: string;
}

export class DriverNewComplaintModel {
  businessentitytypecode : string | number; 
  entitycode : string | number;
  complainttypecode : string | number;
  complaintdetails : string;
}

export class CustomerNewComplaintModel {
  businessentitytypecode : string | number; 
  entitycode : string | number;
  complainttypecode : string | number;
  complaintdetails : string;
}

export class CustomerComplaintActionModel {
  customerid: string;
  complaintno: string;
  userid: string;
  internalcomments: string;
  externalcomments: string;
  complaintstatuscode: number;
  complainttypecode: string;
}

export class DriverComplaintActionModel {
  driverid: string;
  complaintno: string;
  userid: string;
  internalcomments: string;
  externalcomments: string;
  complaintstatuscode: number;
  complainttypecode: string;
}

export interface CustomerRefundsInterface {
    complaintno: string;
    customerid: string;
    customername: string;
    complainttype: string;
}

export interface DriverRefundsInterface {
    complaintno: string;
    driverid: string;
    drivername: string;
    complainttype: string;
}