import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { 
  ENDPOINTS,
  ADMIN_BASE_URL_ENDPOINT,
  SUPPORT_BASE_URL_ENDPOINT } from 'src/app/app.constants';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(private httpClient: HttpClient) { }

  // search customer api
  searchCustomerApi(payload): Observable<any> {
     return this.httpClient.post(`${ADMIN_BASE_URL_ENDPOINT}`+`${ENDPOINTS.CUST_SEARCH}`, payload).pipe(map((data: any) => {
      return data.Result;
    })); 
  }

  // customer detail api
  getCustomerDetailApi(payload): Observable<any> {
     return this.httpClient.post(`${SUPPORT_BASE_URL_ENDPOINT}`+`${ENDPOINTS.CUST_DETAIL}`, payload).pipe(map((data: any) => {
      return data.result;
    })); 
  }

  // get customer tickets api
  getCustomerTicketsApi(payload): Observable<any> {
     return this.httpClient.post(`${SUPPORT_BASE_URL_ENDPOINT}`+`${ENDPOINTS.CUST_TICKETS}`, payload).pipe(map((data: any) => {
      return data.result;
    })); 
  }

  // get customer complaints api
  getCustomerComplaintsApi(payload): Observable<any> {
     return this.httpClient.post(`${SUPPORT_BASE_URL_ENDPOINT}`+`${ENDPOINTS.CUST_COMPLAINTS}`, payload).pipe(map((data: any) => {
      return data.result;
    })); 
  }

  // get customer complaints api
  getCustomerComplaintsAgainstApi(payload): Observable<any> {
     return this.httpClient.post(`${SUPPORT_BASE_URL_ENDPOINT}`+`${ENDPOINTS.CUST_COMPLAINTS_AGAINST}`, payload).pipe(map((data: any) => {
      return data.result;
    })); 
  }

  // get all actions against customer
  getCustomerComplaintActionsApi(payload): Observable<any> {
     return this.httpClient.post(`${SUPPORT_BASE_URL_ENDPOINT}`+`${ENDPOINTS.CUST_COMPLAINT_ACTIONS}`, payload).pipe(map((data: any) => {
      return data.result;
    })); 
  }

  // get customer completed rides
  getCustomerCompletedRidesApi(payload): Observable<any> {
     return this.httpClient.post(`${SUPPORT_BASE_URL_ENDPOINT}`+`${ENDPOINTS.CUST_COMPLETED_RIDES}`, payload).pipe(map((data: any) => {
      return data.result;
    })); 
  }

  // get customer active rides
  getCustomerActiveRidesApi(payload): Observable<any> {
     return this.httpClient.post(`${SUPPORT_BASE_URL_ENDPOINT}`+`${ENDPOINTS.CUST_ACTIVE_RIDES}`, payload).pipe(map((data: any) => {
      return data.result;
    })); 
  }

    // mark driver as suspended / blacklisted
  setCustomerBlacklistApi(payload): Observable<any> {
    return this.httpClient.post(`${SUPPORT_BASE_URL_ENDPOINT}`+`${ENDPOINTS.CUST_BLACKLIST}`, payload).pipe(map((data: any) => {
      return data;
    }));
  }

  // revoke blacklisting on driver
  revokeCustomerBlacklistApi(payload): Observable<any> {
    return this.httpClient.post(`${SUPPORT_BASE_URL_ENDPOINT}`+`${ENDPOINTS.CUST_BLACKLIST_REVOKE}`, payload).pipe(map((data: any) => {
      return data.result;
    }));
  }

  createCustomerSupportTicketApi(payload): Observable<any> {
    return this.httpClient.post(`${SUPPORT_BASE_URL_ENDPOINT}`+`${ENDPOINTS.CUST_NEW_TICKET}`, payload).pipe(map((data: any) => {
      return data.result;
    }));
  }

  createCustomerComplaintApi(payload): Observable<any> {
    return this.httpClient.post(`${SUPPORT_BASE_URL_ENDPOINT}`+`${ENDPOINTS.CUST_NEW_COMPLAINT}`, payload).pipe(map((data: any) => {
      return data.result;
    })); 
  }

  createCustomerTerminateApi(payload): Observable<any> {
    return this.httpClient.post(`${SUPPORT_BASE_URL_ENDPOINT}`+`${ENDPOINTS.Terminate_Customer_Or_Driver}`, payload).pipe(map((data: any) => {
      return data;
    })); 
  }

}
