export interface CustomerOpenTicketsModel {
  serviceticketno: string;
  customerid: string;
  customername: string;
  servicetickettype: string;
}

export interface DriverOpenTicketsModel {
  serviceticketno: string;
  driverid: string;
  drivername: string;
  servicetickettype: string;
}

export class CustomerTicketActionModel {
  customerid: string;
  serviceticketno: string;
  userid: string;
  internalcomments: string;
  externalcomments: string;
}

export class DriverTicketActionModel {
  driverid: string;
  serviceticketno: string;
  userid: string;
  internalcomments: string;
  externalcomments: string;
}

export class DriverNewTicketModel {
    driverid: string | number;
    servicetickettypeid: number | string;
    details: string;
    userid:string|number
}

export class CustomerNewTicketModel {
    customerid: string | number;
    servicetickettypeid: number | string;
    details: string
}

