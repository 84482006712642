import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { map} from 'rxjs/operators';

import { ENDPOINTS, ADMIN_BASE_URL_ENDPOINT, SUPPORT_BASE_URL_ENDPOINT } from 'src/app/app.constants';

@Injectable({
  providedIn: 'root'
})
export class ComplaintService {

  constructor(private httpClient: HttpClient) {}

  // get all complaint types
  getComplaintTypesApi(payload): Observable<any> {
     return this.httpClient.post(`${SUPPORT_BASE_URL_ENDPOINT}`+`${ENDPOINTS.COMPLAINT_TYPES}`, payload).pipe(map((data: any) => {
        return data.result;
      }));
  }

  // get complaints list
  getComplaintsListApi(type): Observable<any> {
    if (type == 'driver') {
      return this.httpClient.post(`${ADMIN_BASE_URL_ENDPOINT}`+`${ENDPOINTS.DRIVER_COMPLAINT_LIST}`,{userid:JSON.parse(localStorage.getItem('userInfo')).userid}).pipe(map((data: any) => {
        return data.Result;
      }));
    }
    else if (type == 'customer') {
      return this.httpClient.post(`${ADMIN_BASE_URL_ENDPOINT}`+`${ENDPOINTS.CUST_COMPLAINT_LIST}`,{userid:JSON.parse(localStorage.getItem('userInfo')).userid}).pipe(map((data: any) => {
        return data.Result;
      }));
    }
  }

  // get complaint details
  getComplaintDetailsApi(role, payload): Observable<any> {
    if (role == 'driver') {
      return this.httpClient.post(`${SUPPORT_BASE_URL_ENDPOINT}`+`${ENDPOINTS.DRIVER_COMPLAINT_DETAIL}`, payload).pipe(map((data: any) => {
        return data.result;
      }));
    }
    else if (role == 'customer') {
      return this.httpClient.post(`${SUPPORT_BASE_URL_ENDPOINT}`+`${ENDPOINTS.CUST_COMPLAINT_DETAIL}`, payload).pipe(map((data: any) => {
        return data.result;
      }));
    }
  }

  // update complaint details
  updateComplaintApi(role, payload): Observable<any> {
    if (role == 'driver') {
      return this.httpClient.post(`${SUPPORT_BASE_URL_ENDPOINT}`+`${ENDPOINTS.DRIVER_UPDATE_COMPLAINT_DETAIL}`, payload).pipe(map((data: any) => {
        return data;
      }));
    }
    else if(role == 'customer') {
      return this.httpClient.post(`${SUPPORT_BASE_URL_ENDPOINT}`+`${ENDPOINTS.CUST_UPDATE_COMPLAINT_DETAIL}`, payload).pipe(map((data: any) => {
        return data;
      }));
    }
  }

  // close/reject complaint status
  closeComplaintApi(role, payload): Observable<any> {
    if (role == 'driver') {
      return this.httpClient.post(`${SUPPORT_BASE_URL_ENDPOINT}`+`${ENDPOINTS.DRIVER_UPDATE_COMPLAINT_STATUS}`, payload).pipe(map((data: any) => {
        return data;
      }));
    }
    else if(role == 'customer') {
      return this.httpClient.post(`${SUPPORT_BASE_URL_ENDPOINT}`+`${ENDPOINTS.CUST_UPDATE_COMPLAINT_STATUS}`, payload).pipe(map((data: any) => {
        return data;
      }));
    }
  }
}
