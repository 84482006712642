
export class DriverTerminateModel 
{
    businessentitytypecode: number;
    holderid: number;
    forceconfirmed: boolean;
    userid: number;
    reason: string;
}
export class CustomerTerminateModel 
{
    businessentitytypecode: number;
    holderid: number;
    forceconfirmed: boolean;
    userid: number;
    reason: string;
}
// vehicleid, userid, reason
  
export class VehicleTerminateModel 
{
    vehicleid: number;
    userid: number;
    reason: string;
}